<template>
    <div>MathField:
        <div class="mathfield" id="mf1">f(x)=</div>

    </div>
</template>

<script type="module">
    import { makeMathField } from '../../../node_modules/mathlive/dist/mathlive.mjs';

    export default {
        name: 'Test',
        mounted() { 
            makeMathField('mf1', {
                smartMode: true, // Makes text entry easier
                virtualKeyboardMode: 'manual', // Always show virtual keyboard glyph
                fontsDirectory: '../fonts'
            });
        }
    }




</script>

<style scoped>
#formula {
  font-size: 200%;
}
</style>

<style>
.ML__keyboard.is-visible {
    z-index: 99999 !important;
}
.ML__keyboard .keyboard-toolbar .right {
    display: none !important;
}

.ML__mathlive {
  padding: 10px;
  color: black !important;
}
</style>